<template>
  <div class="paper_list" v-if="list && list.length > 0">
    <div v-for="(item, index) in list" :key="index" class="practice_item_wrapper">
      <div class="practice_item">
        <img
          class="left_icon"
          v-if="tabs === 8"
          src="@/assets/paper/practice_everyday.svg"
          alt=""
        />
        <img
          class="left_icon"
          v-if="tabs === 7"
          src="@/assets/paper/practice_chapter.svg"
          alt=""
        />
        <img
          class="left_icon"
          v-if="tabs === 9"
          src="@/assets/paper/practice_emigrated.svg"
          alt=""
        />
        <img
          class="left_icon"
          v-if="tabs === 10"
          src="@/assets/paper/mock_exam.svg"
          alt=""
        />
        <div>
          <p class="name mgb10 font16">{{ item.paperName }}</p>
          <p style="height: 30px"></p>
        </div>
        <div class="handle_view" @click="handleView(item)">前往考试</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useUserStore } from "../../stores/user";
import { setStore } from "../../utils/storage";
import { onMounted, reactive, computed } from "vue";
import { ElMessage } from "element-plus";

const route = useRoute();
const router = useRouter();
const store = useUserStore();
const { getEnumList } = store;
const token = computed(() => store.token);
const props = defineProps({
  list: {
    type: Array,
    default: [],
  },
  tabs: {
    type: [String, Number],
    default: "",
  },
  // 会员模块展示商品数据显示会员价格
  isVipModal: {
    type: Boolean,
    default: false,
  },
});
const opts = reactive({
  userPracticeStatusEnum: {},
});
const handleView = (row) => {
  let url = "/onlineExam/examView";
  router.push({
    path: url,
    query: {
      id: row.id || row.goodsId,
    },
  });
};
onMounted(() => {
  getEnumList("UserPracticeStatusEnum", "obj").then((res) => {
    opts.userPracticeStatusEnum = res;
  });
});
</script>

<style scoped lang="scss">
.paper_list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.practice_item_wrapper {
  margin-bottom: 20px;
  padding: 0 10px;
  width: 50%;
}
.practice_item_wrapper:nth-last-child(-n + 2) {
  margin-bottom: 0;
}
.practice_item {
  position: relative;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  column-gap: 12px;
  background: #ffffff;
  border: 1px solid #ebeef5;
  &:hover {
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    transition: all 0.3s;
  }
  .name {
    width: 430px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .current_price {
    color: #f61818;
  }
  .free {
    color: #58b85c;
  }
  .line_price {
    text-decoration: line-through;
    margin-left: 5px;
  }
  .handle_btn {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .handle_view {
    position: absolute;
    right: 20px;
    bottom: 20px;
    background: rgba(61, 127, 255, 0.1);
    color: #3d7fff;
    padding: 6px 17px;
    border-radius: 5px;
  }
}
</style>
